// js
$(document).ready(function(){
  AOS.init();


    $('.modal-comunicado').modal('show');



// resposta do contact form modal


  $(".wpcf7-submit").click(function(event) {
    $( document ).ajaxComplete(function() {
      if (!$('.wpcf7-form-control-wrap span').hasClass('wpcf7-not-valid-tip')) {
      $('.modal-resposta').addClass('show');
      $('.modal-resposta-mobile').addClass('show');
      $('.modal-resposta .modal-body').append($(".wpcf7-mail-sent-ok"));
    }
    });
  });

  $(".ico-close-modal").click(function() {
      $('.modal-resposta').removeClass('show');
      $('.modal-resposta-mobile').removeClass('show');
  });


  //filtro de pesquisa

  $('.dropdown-item').click(function () {
    $(this).addClass('active').siblings().removeClass('active');
  });
  $('.dropdown-menu.ano .dropdown-item').click(function () {
    $('.dropdown-menu.mes .dropdown-item').removeClass('active');
  });

  $('.doc-none:first-child').addClass('d-block');

  $('.dropdown-menu.ano .dropdown-item:first-child').addClass('active');

  // Condição para mostrar navegacao flickity

  // Janeiro 2022 - 2015
  var count_2022_month_01 = $('.docs-carousel.janeiro-2022 .carousel-cell').length;
  var count_2021_month_01 = $('.docs-carousel.janeiro-2021 .carousel-cell').length;
  var count_2020_month_01 = $('.docs-carousel.janeiro-2020 .carousel-cell').length;
  var count_2019_month_01 = $('.docs-carousel.janeiro-2019 .carousel-cell').length;
  var count_2018_month_01 = $('.docs-carousel.janeiro-2018 .carousel-cell').length;
  var count_2017_month_01 = $('.docs-carousel.janeiro-2017 .carousel-cell').length;
  var count_2016_month_01 = $('.docs-carousel.janeiro-2016 .carousel-cell').length;
  var count_2015_month_01 = $('.docs-carousel.janeiro-2015 .carousel-cell').length;
  if ( count_2022_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_01 <= 2 ) {
    $('.docs-carousel.janeiro-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }

  // fevereiro 2022 - 2015
  var count_2022_month_02 = $('.docs-carousel.fevereiro-2022 .carousel-cell').length;
  var count_2021_month_02 = $('.docs-carousel.fevereiro-2021 .carousel-cell').length;
  var count_2020_month_02 = $('.docs-carousel.fevereiro-2020 .carousel-cell').length;
  var count_2019_month_02 = $('.docs-carousel.fevereiro-2019 .carousel-cell').length;
  var count_2018_month_02 = $('.docs-carousel.fevereiro-2018 .carousel-cell').length;
  var count_2017_month_02 = $('.docs-carousel.fevereiro-2017 .carousel-cell').length;
  var count_2016_month_02 = $('.docs-carousel.fevereiro-2016 .carousel-cell').length;
  var count_2015_month_02 = $('.docs-carousel.fevereiro-2015 .carousel-cell').length;
  if ( count_2022_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_02 <= 2 ) {
    $('.docs-carousel.fevereiro-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // marco 2022 - 2015
  var count_2022_month_03 = $('.docs-carousel.marco-2022 .carousel-cell').length;
  var count_2021_month_03 = $('.docs-carousel.marco-2021 .carousel-cell').length;
  var count_2020_month_03 = $('.docs-carousel.marco-2020 .carousel-cell').length;
  var count_2019_month_03 = $('.docs-carousel.marco-2019 .carousel-cell').length;
  var count_2018_month_03 = $('.docs-carousel.marco-2018 .carousel-cell').length;
  var count_2017_month_03 = $('.docs-carousel.marco-2017 .carousel-cell').length;
  var count_2016_month_03 = $('.docs-carousel.marco-2016 .carousel-cell').length;
  var count_2015_month_03 = $('.docs-carousel.marco-2015 .carousel-cell').length;
  if ( count_2022_month_03 <= 2 ) {
    $('.docs-carousel.marco-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_03 <= 2 ) {
    $('.docs-carousel.marco-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_03 <= 2 ) {
    $('.docs-carousel.marco-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_03 <= 2 ) {
    $('.docs-carousel.marco-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_03 <= 2 ) {
    $('.docs-carousel.marco-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_03 <= 2 ) {
    $('.docs-carousel.marco-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_03 <= 2 ) {
    $('.docs-carousel.marco-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_03 <= 2 ) {
    $('.docs-carousel.marco-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // abril 2022 - 2015
  var count_2022_month_04 = $('.docs-carousel.abril-2022 .carousel-cell').length;
  var count_2021_month_04 = $('.docs-carousel.abril-2021 .carousel-cell').length;
  var count_2020_month_04 = $('.docs-carousel.abril-2020 .carousel-cell').length;
  var count_2019_month_04 = $('.docs-carousel.abril-2019 .carousel-cell').length;
  var count_2018_month_04 = $('.docs-carousel.abril-2018 .carousel-cell').length;
  var count_2017_month_04 = $('.docs-carousel.abril-2017 .carousel-cell').length;
  var count_2016_month_04 = $('.docs-carousel.abril-2016 .carousel-cell').length;
  var count_2015_month_04 = $('.docs-carousel.abril-2015 .carousel-cell').length;
  if ( count_2022_month_04 <= 2 ) {
    $('.docs-carousel.abril-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_04 <= 2 ) {
    $('.docs-carousel.abril-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_04 <= 2 ) {
    $('.docs-carousel.abril-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_04 <= 2 ) {
    $('.docs-carousel.abril-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_04 <= 2 ) {
    $('.docs-carousel.abril-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_04 <= 2 ) {
    $('.docs-carousel.abril-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_04 <= 2 ) {
    $('.docs-carousel.abril-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_04 <= 2 ) {
    $('.docs-carousel.abril-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // maio 2022 - 2015
  var count_2022_month_05 = $('.docs-carousel.maio-2022 .carousel-cell').length;
  var count_2021_month_05 = $('.docs-carousel.maio-2021 .carousel-cell').length;
  var count_2020_month_05 = $('.docs-carousel.maio-2020 .carousel-cell').length;
  var count_2019_month_05 = $('.docs-carousel.maio-2019 .carousel-cell').length;
  var count_2018_month_05 = $('.docs-carousel.maio-2018 .carousel-cell').length;
  var count_2017_month_05 = $('.docs-carousel.maio-2017 .carousel-cell').length;
  var count_2016_month_05 = $('.docs-carousel.maio-2016 .carousel-cell').length;
  var count_2015_month_05 = $('.docs-carousel.maio-2015 .carousel-cell').length;
  if ( count_2022_month_05 <= 2 ) {
    $('.docs-carousel.maio-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_05 <= 2 ) {
    $('.docs-carousel.maio-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_05 <= 2 ) {
    $('.docs-carousel.maio-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_05 <= 2 ) {
    $('.docs-carousel.maio-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_05 <= 2 ) {
    $('.docs-carousel.maio-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_05 <= 2 ) {
    $('.docs-carousel.maio-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_05 <= 2 ) {
    $('.docs-carousel.maio-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_05 <= 2 ) {
    $('.docs-carousel.maio-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // junho 2022 - 2015
  var count_2022_month_06 = $('.docs-carousel.junho-2022 .carousel-cell').length;
  var count_2021_month_06 = $('.docs-carousel.junho-2021 .carousel-cell').length;
  var count_2020_month_06 = $('.docs-carousel.junho-2020 .carousel-cell').length;
  var count_2019_month_06 = $('.docs-carousel.junho-2019 .carousel-cell').length;
  var count_2018_month_06 = $('.docs-carousel.junho-2018 .carousel-cell').length;
  var count_2017_month_06 = $('.docs-carousel.junho-2017 .carousel-cell').length;
  var count_2016_month_06 = $('.docs-carousel.junho-2016 .carousel-cell').length;
  var count_2015_month_06 = $('.docs-carousel.junho-2015 .carousel-cell').length;
  if ( count_2022_month_06 <= 2 ) {
    $('.docs-carousel.junho-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_06 <= 2 ) {
    $('.docs-carousel.junho-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_06 <= 2 ) {
    $('.docs-carousel.junho-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_06 <= 2 ) {
    $('.docs-carousel.junho-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_06 <= 2 ) {
    $('.docs-carousel.junho-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_06 <= 2 ) {
    $('.docs-carousel.junho-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_06 <= 2 ) {
    $('.docs-carousel.junho-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_06 <= 2 ) {
    $('.docs-carousel.junho-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // julho 2022 - 2015
  var count_2022_month_07 = $('.docs-carousel.julho-2022 .carousel-cell').length;
  var count_2021_month_07 = $('.docs-carousel.julho-2021 .carousel-cell').length;
  var count_2020_month_07 = $('.docs-carousel.julho-2020 .carousel-cell').length;
  var count_2019_month_07 = $('.docs-carousel.julho-2019 .carousel-cell').length;
  var count_2018_month_07 = $('.docs-carousel.julho-2018 .carousel-cell').length;
  var count_2017_month_07 = $('.docs-carousel.julho-2017 .carousel-cell').length;
  var count_2016_month_07 = $('.docs-carousel.julho-2016 .carousel-cell').length;
  var count_2015_month_07 = $('.docs-carousel.julho-2015 .carousel-cell').length;
  if ( count_2022_month_07 <= 2 ) {
    $('.docs-carousel.julho-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_07 <= 2 ) {
    $('.docs-carousel.julho-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_07 <= 2 ) {
    $('.docs-carousel.julho-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_07 <= 2 ) {
    $('.docs-carousel.julho-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_07 <= 2 ) {
    $('.docs-carousel.julho-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_07 <= 2 ) {
    $('.docs-carousel.julho-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_07 <= 2 ) {
    $('.docs-carousel.julho-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_07 <= 2 ) {
    $('.docs-carousel.julho-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // agosto 2022 - 2015
  var count_2022_month_08 = $('.docs-carousel.agosto-2022 .carousel-cell').length;
  var count_2021_month_08 = $('.docs-carousel.agosto-2021 .carousel-cell').length;
  var count_2020_month_08 = $('.docs-carousel.agosto-2020 .carousel-cell').length;
  var count_2019_month_08 = $('.docs-carousel.agosto-2019 .carousel-cell').length;
  var count_2018_month_08 = $('.docs-carousel.agosto-2018 .carousel-cell').length;
  var count_2017_month_08 = $('.docs-carousel.agosto-2017 .carousel-cell').length;
  var count_2016_month_08 = $('.docs-carousel.agosto-2016 .carousel-cell').length;
  var count_2015_month_08 = $('.docs-carousel.agosto-2015 .carousel-cell').length;
  if ( count_2022_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_08 <= 2 ) {
    $('.docs-carousel.agosto-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // setembro 2022 - 2015
  var count_2022_month_09 = $('.docs-carousel.setembro-2022 .carousel-cell').length;
  var count_2021_month_09 = $('.docs-carousel.setembro-2021 .carousel-cell').length;
  var count_2020_month_09 = $('.docs-carousel.setembro-2020 .carousel-cell').length;
  var count_2019_month_09 = $('.docs-carousel.setembro-2019 .carousel-cell').length;
  var count_2018_month_09 = $('.docs-carousel.setembro-2018 .carousel-cell').length;
  var count_2017_month_09 = $('.docs-carousel.setembro-2017 .carousel-cell').length;
  var count_2016_month_09 = $('.docs-carousel.setembro-2016 .carousel-cell').length;
  var count_2015_month_09 = $('.docs-carousel.setembro-2015 .carousel-cell').length;
  if ( count_2022_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_09 <= 2 ) {
    $('.docs-carousel.setembro-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // outubro 2022 - 2015
  var count_2022_month_10 = $('.docs-carousel.outubro-2022 .carousel-cell').length;
  var count_2021_month_10 = $('.docs-carousel.outubro-2021 .carousel-cell').length;
  var count_2020_month_10 = $('.docs-carousel.outubro-2020 .carousel-cell').length;
  var count_2019_month_10 = $('.docs-carousel.outubro-2019 .carousel-cell').length;
  var count_2018_month_10 = $('.docs-carousel.outubro-2018 .carousel-cell').length;
  var count_2017_month_10 = $('.docs-carousel.outubro-2017 .carousel-cell').length;
  var count_2016_month_10 = $('.docs-carousel.outubro-2016 .carousel-cell').length;
  var count_2015_month_10 = $('.docs-carousel.outubro-2015 .carousel-cell').length;
  if ( count_2022_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_10 <= 2 ) {
    $('.docs-carousel.outubro-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // novembro 2022 - 2015
  var count_2022_month_11 = $('.docs-carousel.novembro-2022 .carousel-cell').length;
  var count_2021_month_11 = $('.docs-carousel.novembro-2021 .carousel-cell').length;
  var count_2020_month_11 = $('.docs-carousel.novembro-2020 .carousel-cell').length;
  var count_2019_month_11 = $('.docs-carousel.novembro-2019 .carousel-cell').length;
  var count_2018_month_11 = $('.docs-carousel.novembro-2018 .carousel-cell').length;
  var count_2017_month_11 = $('.docs-carousel.novembro-2017 .carousel-cell').length;
  var count_2016_month_11 = $('.docs-carousel.novembro-2016 .carousel-cell').length;
  var count_2015_month_11 = $('.docs-carousel.novembro-2015 .carousel-cell').length;
  if ( count_2022_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_11 <= 2 ) {
    $('.docs-carousel.novembro-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  // dezembro 2022 - 2015
  var count_2022_month_12 = $('.docs-carousel.dezembro-2022 .carousel-cell').length;
  var count_2021_month_12 = $('.docs-carousel.dezembro-2021 .carousel-cell').length;
  var count_2020_month_12 = $('.docs-carousel.dezembro-2020 .carousel-cell').length;
  var count_2019_month_12 = $('.docs-carousel.dezembro-2019 .carousel-cell').length;
  var count_2018_month_12 = $('.docs-carousel.dezembro-2018 .carousel-cell').length;
  var count_2017_month_12 = $('.docs-carousel.dezembro-2017 .carousel-cell').length;
  var count_2016_month_12 = $('.docs-carousel.dezembro-2016 .carousel-cell').length;
  var count_2015_month_12 = $('.docs-carousel.dezembro-2015 .carousel-cell').length;
  if ( count_2022_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2021_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2020_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2019_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2018_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2017_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2016_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_2015_month_12 <= 2 ) {
    $('.docs-carousel.dezembro-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }


  var count_year_2022 = $('.docs-carousel#all-2022 .carousel-cell').length;
  var count_year_2021 = $('.docs-carousel#all-2021 .carousel-cell').length;
  var count_year_2020 = $('.docs-carousel#all-2020 .carousel-cell').length;
  var count_year_2019 = $('.docs-carousel#all-2019 .carousel-cell').length;
  var count_year_2018 = $('.docs-carousel#all-2018 .carousel-cell').length;
  var count_year_2017 = $('.docs-carousel#all-2017 .carousel-cell').length;
  var count_year_2016 = $('.docs-carousel#all-2016 .carousel-cell').length;
  var count_year_2015 = $('.docs-carousel#all-2015 .carousel-cell').length;
  if ( count_year_2022 <= 2 ) {
    $('.docs-carousel#all-2022').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_year_2021 <= 2 ) {
    $('.docs-carousel#all-2021').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_year_2020 <= 2 ) {
    $('.docs-carousel#all-2020').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_year_2019 <= 2 ) {
    $('.docs-carousel#all-2019').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_year_2018 <= 2 ) {
    $('.docs-carousel#all-2018').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_year_2017 <= 2 ) {
    $('.docs-carousel#all-2017').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_year_2016 <= 2 ) {
    $('.docs-carousel#all-2016').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }
  if ( count_year_2015 <= 2 ) {
    $('.docs-carousel#all-2015').flickity({
      prevNextButtons: false,
      pageDots: false
    });
  }


  // ANOS

  $('.dropdown-item.item-2022').click(function () {
    $('#year-2022').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2022, .fevereiro-2022, .marco-2022, .abril-2022, .maio-2022, .junho-2022, .julho-2022, .agosto-2022, .setembro-2022, .outubro-2022, .novembro-2022, .dezembro-2022').hasClass('d-block')) {
      $('.janeiro-2022, .fevereiro-2022, .marco-2022, .abril-2022, .maio-2022, .junho-2022, .julho-2022, .agosto-2022, .setembro-2022, .outubro-2022, .novembro-2022, .dezembro-2022').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2022').hasClass('d-none')) {
      $('#all-2022').removeClass('d-none').addClass('d-block');
    }
  });
  $('.dropdown-item.item-2021').click(function () {
    $('#year-2021').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2021, .fevereiro-2021, .marco-2021, .abril-2021, .maio-2021, .junho-2021, .julho-2021, .agosto-2021, .setembro-2021, .outubro-2021, .novembro-2021, .dezembro-2021').hasClass('d-block')) {
      $('.janeiro-2021, .fevereiro-2021, .marco-2021, .abril-2021, .maio-2021, .junho-2021, .julho-2021, .agosto-2021, .setembro-2021, .outubro-2021, .novembro-2021, .dezembro-2021').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2021').hasClass('d-none')) {
      $('#all-2021').removeClass('d-none').addClass('d-block');
    }
  });
  $('.dropdown-item.item-2020').click(function () {
    $('#year-2020').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2020, .fevereiro-2020, .marco-2020, .abril-2020, .maio-2020, .junho-2020, .julho-2020, .agosto-2020, .setembro-2020, .outubro-2020, .novembro-2020, .dezembro-2020').hasClass('d-block')) {
      $('.janeiro-2020, .fevereiro-2020, .marco-2020, .abril-2020, .maio-2020, .junho-2020, .julho-2020, .agosto-2020, .setembro-2020, .outubro-2020, .novembro-2020, .dezembro-2020').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2020').hasClass('d-none')) {
      $('#all-2020').removeClass('d-none').addClass('d-block');
    }
  });
  $('.dropdown-item.item-2019').click(function () {
    $('#year-2019').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2019, .fevereiro-2019, .marco-2019, .abril-2019, .maio-2019, .junho-2019, .julho-2019, .agosto-2019, .setembro-2019, .outubro-2019, .novembro-2019, .dezembro-2019').hasClass('d-block')) {
      $('.janeiro-2019, .fevereiro-2019, .marco-2019, .abril-2019, .maio-2019, .junho-2019, .julho-2019, .agosto-2019, .setembro-2019, .outubro-2019, .novembro-2019, .dezembro-2019').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2019').hasClass('d-none')) {
      $('#all-2019').removeClass('d-none').addClass('d-block');
    }
  });
  $('.dropdown-item.item-2018').click(function () {
    $('#year-2018').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2018, .fevereiro-2018, .marco-2018, .abril-2018, .maio-2018, .junho-2018, .julho-2018, .agosto-2018, .setembro-2018, .outubro-2018, .novembro-2018, .dezembro-2018').hasClass('d-block')) {
      $('.janeiro-2018, .fevereiro-2018, .marco-2018, .abril-2018, .maio-2018, .junho-2018, .julho-2018, .agosto-2018, .setembro-2018, .outubro-2018, .novembro-2018, .dezembro-2018').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2018').hasClass('d-none')) {
      $('#all-2018').removeClass('d-none').addClass('d-block');
    }
  });
  $('.dropdown-item.item-2017').click(function () {
    $('#year-2017').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2017, .fevereiro-2017, .marco-2017, .abril-2017, .maio-2017, .junho-2017, .julho-2017, .agosto-2017, .setembro-2017, .outubro-2017, .novembro-2017, .dezembro-2017').hasClass('d-block')) {
      $('.janeiro-2017, .fevereiro-2017, .marco-2017, .abril-2017, .maio-2017, .junho-2017, .julho-2017, .agosto-2017, .setembro-2017, .outubro-2017, .novembro-2017, .dezembro-2017').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2017').hasClass('d-none')) {
      $('#all-2017').removeClass('d-none').addClass('d-block');
    }
  });
  $('.dropdown-item.item-2016').click(function () {
    $('#year-2016').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2016, .fevereiro-2016, .marco-2016, .abril-2016, .maio-2016, .junho-2016, .julho-2016, .agosto-2016, .setembro-2016, .outubro-2016, .novembro-2016, .dezembro-2016').hasClass('d-block')) {
      $('.janeiro-2016, .fevereiro-2016, .marco-2016, .abril-2016, .maio-2016, .junho-2016, .julho-2016, .agosto-2016, .setembro-2016, .outubro-2016, .novembro-2016, .dezembro-2016').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2016').hasClass('d-none')) {
      $('#all-2016').removeClass('d-none').addClass('d-block');
    }
  });
  $('.dropdown-item.item-2015').click(function () {
    $('#year-2015').addClass('active').siblings().removeClass('active');
    if ($('.janeiro-2015, .fevereiro-2015, .marco-2015, .abril-2015, .maio-2015, .junho-2015, .julho-2015, .agosto-2015, .setembro-2015, .outubro-2015, .novembro-2015, .dezembro-2015').hasClass('d-block')) {
      $('.janeiro-2015, .fevereiro-2015, .marco-2015, .abril-2015, .maio-2015, .junho-2015, .julho-2015, .agosto-2015, .setembro-2015, .outubro-2015, .novembro-2015, .dezembro-2015').removeClass('d-block').addClass('d-none');
    }
    if ($('#all-2015').hasClass('d-none')) {
      $('#all-2015').removeClass('d-none').addClass('d-block');
    }
  });

  // MESES

  $('.dropdown-item.m-01').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.janeiro-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.janeiro-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.janeiro-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.janeiro-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.janeiro-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.janeiro-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.janeiro-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.janeiro-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-02').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.fevereiro-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.fevereiro-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.fevereiro-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.fevereiro-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.fevereiro-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.fevereiro-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.fevereiro-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.fevereiro-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-03').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.marco-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.marco-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.marco-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.marco-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.marco-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.marco-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.marco-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.marco-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-04').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.abril-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.abril-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.abril-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.abril-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.abril-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.abril-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.abril-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.abril-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-05').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.abril-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.abril-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.maio-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.maio-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.maio-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.maio-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.maio-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.maio-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-06').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.junho-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.junho-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.junho-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.junho-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.junho-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.junho-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.junho-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.junho-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-07').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.julho-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.julho-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.julho-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.julho-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.julho-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.julho-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.julho-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.julho-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-08').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.agosto-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.agosto-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.agosto-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.agosto-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.agosto-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.agosto-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.agosto-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.agosto-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-09').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.setembro-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.setembro-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.setembro-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.setembro-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.setembro-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.setembro-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.setembro-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.setembro-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-10').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.outubro-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.outubro-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.outubro-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.outubro-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.outubro-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.outubro-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.outubro-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.outubro-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-11').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.novembro-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.novembro-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.novembro-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.novembro-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.novembro-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.novembro-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.novembro-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.novembro-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });
  $('.dropdown-item.m-12').click(function () {
    if ($('.item-2022').hasClass('active')) {
      $('.docs-carousel.dezembro-2022').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2021').hasClass('active')) {
      $('.docs-carousel.dezembro-2021').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2020').hasClass('active')) {
      $('.docs-carousel.dezembro-2020').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2019').hasClass('active')) {
      $('.docs-carousel.dezembro-2019').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2018').hasClass('active')) {
      $('.docs-carousel.dezembro-2018').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2017').hasClass('active')) {
      $('.docs-carousel.dezembro-2017').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2016').hasClass('active')) {
      $('.docs-carousel.dezembro-2016').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
    if ($('.item-2015').hasClass('active')) {
      $('.docs-carousel.dezembro-2015').removeClass('d-none').addClass('d-block').siblings().removeClass('d-block').addClass('d-none');
    }
  });




  //hamburger close open
  $('.navbar-toggler').click(function () {
    if ($('.navbar-toggler-icon').hasClass('active')) {
      $('.navbar-toggler-icon').removeClass('active');
    } else {
      $('.navbar-toggler-icon').addClass('active');
    }
  });
  // arrow btn dropdown

  $('.btn-dropdown').click(function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }
  });
  $(document).click(function(){
    $('.btn-dropdown').removeClass('active');
  });

  // carousel Documentos
  $('.docs-carousel').flickity({
    cellSelector: '.carousel-cell',
    groupCells: 2,
    cellAlign: 'left',
    resize: true
  });

  $('.dropdown-item').click(function(event){
    $('.docs-carousel').flickity('resize');
  });

  $('.table-carousel, .table-carousel-mob').flickity({
    cellSelector: '.carousel-cell'
  });

  // carousel Gallery
  $('.gallery-carousel').flickity({
    cellSelector: '.carousel-cell',
    groupCells: 4,
    cellAlign: 'left'
  });

  $('.gallery-carousel-modal').flickity({
    cellSelector: '.carousel-cell',
    groupCells: 4,
    cellAlign: 'left',
    pageDots: false
  });


  if ($('.display-img').parent('.empreendimento-1').length) {
    $('.empreendimento-1 .display-img').attr('data-target','#modal-gallery-2');
  }

  if ($('.display-img').parent('.empreendimento-3').length) {
    $('.empreendimento-3 .display-img').attr('data-target','#modal-gallery-4');
  }
  if ($('.display-img').parent('.empreendimento-5').length) {
    $('.empreendimento-5 .display-img').attr('data-target','#modal-gallery-6');
  }
  if ($('.display-img').parent('.empreendimento-7').length) {
    $('.empreendimento-7 .display-img').attr('data-target','#modal-gallery-8');
  }
  if ($('.display-img').parent('.empreendimento-9').length) {
    $('.empreendimento-9 .display-img').attr('data-target','#modal-gallery-10');
  }
  if ($('.display-img').parent('.empreendimento-11').length) {
    $('.empreendimento-11 .display-img').attr('data-target','#modal-gallery-12');
  }
  if ($('.display-img').parent('.empreendimento-13').length) {
    $('.empreendimento-13 .display-img').attr('data-target','#modal-gallery-14');
  }
  if ($('.display-img').parent('.empreendimento-15').length) {
    $('.empreendimento-15 .display-img').attr('data-target','#modal-gallery-16');
  }


  $('#modal-gallery').on( 'shown.bs.modal', function( event ) {
    $('.gallery-carousel-modal').flickity('resize');
  });

  $('.modal-gallery-lessimg').on( 'shown.bs.modal', function( event ) {
    $('.gallery-carousel-modal').flickity('resize');
  });

  $('.empreendimento .display-img:nth-child(2)').click(function(){
    $('.wrapper-img:nth-child(1)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.empreendimento .display-img:nth-child(3)').click(function(){
    $('.wrapper-img:nth-child(2)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.empreendimento .display-img:nth-child(4)').click(function(){
    $('.wrapper-img:nth-child(3)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.empreendimento .display-img:nth-child(5)').click(function(){
    $('.wrapper-img:nth-child(4)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(1), .gallery-carousel-modal .carousel-cell:nth-child(1)').click(function(){
    $('.wrapper-img:nth-child(1)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(2), .gallery-carousel-modal .carousel-cell:nth-child(2)').click(function(){
    $('.wrapper-img:nth-child(2)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(3), .gallery-carousel-modal .carousel-cell:nth-child(3)').click(function(){
    $('.wrapper-img:nth-child(3)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(4), .gallery-carousel-modal .carousel-cell:nth-child(4)').click(function(){
    $('.wrapper-img:nth-child(4)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(5), .gallery-carousel-modal .carousel-cell:nth-child(5)').click(function(){
    $('.wrapper-img:nth-child(5)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(6), .gallery-carousel-modal .carousel-cell:nth-child(6)').click(function(){
    $('.wrapper-img:nth-child(6)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(7), .gallery-carousel-modal .carousel-cell:nth-child(7)').click(function(){
    $('.wrapper-img:nth-child(7)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(8), .gallery-carousel-modal .carousel-cell:nth-child(8)').click(function(){
    $('.wrapper-img:nth-child(8)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(9), .gallery-carousel-modal .carousel-cell:nth-child(9)').click(function(){
    $('.wrapper-img:nth-child(9)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(10), .gallery-carousel-modal .carousel-cell:nth-child(10)').click(function(){
    $('.wrapper-img:nth-child(10)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(11), .gallery-carousel-modal .carousel-cell:nth-child(11)').click(function(){
    $('.wrapper-img:nth-child(11)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(12), .gallery-carousel-modal .carousel-cell:nth-child(12)').click(function(){
    $('.wrapper-img:nth-child(12)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(13), .gallery-carousel-modal .carousel-cell:nth-child(13)').click(function(){
    $('.wrapper-img:nth-child(13)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(14), .gallery-carousel-modal .carousel-cell:nth-child(14)').click(function(){
    $('.wrapper-img:nth-child(14)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(15), .gallery-carousel-modal .carousel-cell:nth-child(15)').click(function(){
    $('.wrapper-img:nth-child(15)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(16), .gallery-carousel-modal .carousel-cell:nth-child(16)').click(function(){
    $('.wrapper-img:nth-child(16)').removeClass('d-none').siblings().addClass('d-none');
  });

  $('.gallery-carousel .carousel-cell:nth-child(17), .gallery-carousel-modal .carousel-cell:nth-child(17)').click(function(){
    $('.wrapper-img:nth-child(17)').removeClass('d-none').siblings().addClass('d-none');
  });
  $('.gallery-carousel .carousel-cell:nth-child(18), .gallery-carousel-modal .carousel-cell:nth-child(18)').click(function(){
    $('.wrapper-img:nth-child(18)').removeClass('d-none').siblings().addClass('d-none');
  });
  $('.gallery-carousel .carousel-cell:nth-child(19), .gallery-carousel-modal .carousel-cell:nth-child(19)').click(function(){
    $('.wrapper-img:nth-child(19)').removeClass('d-none').siblings().addClass('d-none');
  });
  $('.gallery-carousel .carousel-cell:nth-child(20), .gallery-carousel-modal .carousel-cell:nth-child(20)').click(function(){
    $('.wrapper-img:nth-child(20)').removeClass('d-none').siblings().addClass('d-none');
  });

  // carousel Gallery
  $('.gallery-carousel-mob, .empreendimentos-carousel').flickity({
    cellSelector: '.carousel-cell'
  });


  //load more posts
  $('#btn-load-more').click(function () {
    $('#btn-load-more').addClass('d-none');
    $('#load-more-posts').addClass('show');
  });

  //scroll to top button
  $('.top').click(function () {
    $('.top').tooltip('hide');
    $('body,html').animate({
      scrollTop: 0
    }, 800);
    return false;
  });

  // nav active
  var url = window.location;
  var element = $('ul a').filter(function() {
    return this.href == url || url.href.indexOf(this.href) == 0; }).parent().addClass('active');
    if (element.is('li')) {
      element.addClass('active').parent().parent('li').addClass('active')
    }

    // header white onscroll
    var header = $("header");

    $(window).scroll(function() {
      var scroll = $(window).scrollTop();

      if (scroll >= 20) {
        header.addClass('scroll');
      } else {
        header.removeClass('scroll');
      }
    });

    // header noticias

    $(function() {
      var loc = window.location.href;
      if(/noticias/.test(loc)) {
        $('header').addClass('scroll');
        var header = $("header");
        $(window).scroll(function() {
          var scroll = $(window).scrollTop();
          if (scroll = 0) {
            header.addClass('scroll');
          } else {
            header.addClass('scroll');
          }
        });
      }
    });

//hide navigation flickty when one slide

function x() {
 $('.table-carousel').each(function() {
   if ($(this).find('div.carousel-cell').length === 1) {
      $(this).find('button.flickity-prev-next-button.previous, button.flickity-prev-next-button.next, ol.flickity-page-dots').hide();
   }
 });
};
setTimeout(x,5);


  });
